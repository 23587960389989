var generic = generic || {};
var site = site || {};

(function ($) {
  site.countryChooser = {
    updateLowerEnvsUrl: function ($templateContainer, environment) {
      var $linksList = $('.js-menu__link', $templateContainer);
      var urls = {
        'http://www.darphin.es': {
          dev: 'https://e.darphin.emea3.eu.dev.emea.elcdev.net',
          stage: 'https://e.darphin.emea3.eu.stage.emea.elcdev.net'
        },
        'http://www.darphin.it': {
          dev: 'https://e.darphin.emea3.eu.dev.emea.elcdev.net',
          stage: 'https://e.darphin.emea3.eu.stage.emea.elcdev.net'
        },
        'http://www.darphin.de': {
          dev: 'https://e.darphin.emea3.eu.dev.emea.elcdev.net',
          stage: 'https://e.darphin.emea3.eu.stage.emea.elcdev.net'
        },
        'www.darphin.eu': {
          dev: 'https://e.darphin.emea3.eu.dev.emea.elcdev.net',
          stage: 'https://e.darphin.emea3.eu.stage.emea.elcdev.net'
        },
        'www.darphin.fr': {
          dev: 'https://e.darphin.emea3.fr.dev.emea.elcdev.net',
          stage: 'https://e.darphin.emea3.fr.stage.emea.elcdev.net'
        },
        'www.darphin.ca': {
          dev: 'https://e.darphin.na.ca.dev.ncsa.elcdev.net',
          stage: 'https://e.darphin.na.ca.stage.ncsa.elcdev.net'
        },
        'www.darphin.com': {
          dev: 'https://e.darphin.na.us.dev.ncsa.elcdev.net',
          stage: 'https://e.darphin.na.us.stage.ncsa.elcdev.net'
        },
        'www.darphin.co.uk': {
          dev: 'https://darphin.uk.dev.emea.perlgem.esteeonline.com',
          stage: 'https://darphin.uk.stage.emea.perlgem.esteeonline.com'
        },
        'www.darphin.gr': {
          dev: 'https://c.darphin.gr.dev.emea.elcdev.net',
          stage: 'https://c.darphin.gr.stage.emea.elcdev.net'
        },
        'www.darphin.com.hk': {
          dev: 'https://c.darphin.hk.dev.kidc.elcdev.net',
          stage: 'https://c.darphin.hk.stage.kidc.elcdev.net'
        },
        'www.darphin-paris.ru': {
          dev: 'https://c.darphin.ru.dev.kidc.elcdev.net',
          stage: 'https://c.darphin.ru.stage.kidc.elcdev.net'
        },
        'www.darphin.co.kr': {
          dev: 'https://c.darphin.kr.dev.kidc.elcdev.net',
          stage: 'https://c.darphin.kr.stage.kidc.elcdev.net'
        },
        'www.darphin.com.tw': {
          dev: 'https://c.darphin.tw.dev.kidc.elcdev.net',
          stage: 'https://c.darphin.tw.stage.kidc.elcdev.net'
        },
        'www.darphin.com.tr': {
          dev: 'https://c.darphin.tr.dev.emea.elcdev.net',
          stage: 'https://c.darphin.tr.stage.emea.elcdev.net'
        }
      };

      $linksList.each((indexList) => {
        var link = $linksList[indexList];
        var keys = Object.keys(urls);
        var keyList = keys.find((value) => {
          var urlMenu = link && link.href;

          return urlMenu.indexOf(value) !== -1 && urlMenu.indexOf(`${value}.`) === -1;
        });

        if (keyList) {
          link.href = urls && urls[keyList] && urls[keyList][environment] || link.href;
        }
      });
    },
    templateContainer: $('.country-chooser'),

    initCountryChooser: function () {
      var containerNode = this.templateContainer;
      var srcUrl = window.top.document.location.href;
      var environment;
      var urlsNa = {
        dev: [
          'e.darphin.na.ca.dev.ncsa.elcdev.net',
          'e.darphin.na.us.dev.ncsa.elcdev.net',
          'frca-darphin-dev.elc.onelink-translations.com'
        ],
        stage: [
          'e.darphin.na.ca.stage.ncsa.elcdev.net',
          'e.darphin.na.us.stage.ncsa.elcdev.net',
          'frca-darphin-stage.elc.onelink-translations.com'
        ]
      };

      environment = urlsNa.dev.some((env) => srcUrl.indexOf(env) !== -1) ? 'dev' : undefined;
      environment = urlsNa.stage.some((env) => srcUrl.indexOf(env) !== -1) ? 'stage' : environment;

      if (environment && environment.length > 0) {
        this.updateLowerEnvsUrl(containerNode, environment);
      }

      if (!containerNode) {
        return null;
      }

      var $countrySelector = $('.country-chooser__selector');
      var $countryMenu = containerNode.find('.menu');

      // Initialize the country chooser
      $countrySelector.on('click', function (e) {
        e.preventDefault();
        $(this).addClass('active');
        var h = $countrySelector.outerHeight(true);

        $countryMenu
          .css({ bottom: h + 'px' })
          .stop()
          .delay(100)
          .slideToggle(300, function () {
            $countryMenu.is(':visible') || $countrySelector.removeClass('active');
            $countryMenu.css({ overflow: 'auto' });
          });

        return !1;
      });

      $countrySelector.on('clickoutside', function (e) {
        $countryMenu.slideUp(200);
        $(this).removeClass('active');
      });
    }
  };

  Drupal.behaviors.countryChooserV1 = {
    attach: function (context, settings) {
      site.countryChooser.initCountryChooser();
    }
  };
})(jQuery);
